import React from 'react';
import { Link } from 'gatsby';
import 'typeface-ubuntu';
import 'typeface-montserrat';
import 'typeface-cardo';
import 'typeface-gothic-a1';
import Hidden from '@material-ui/core/Hidden';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './css/video.css';

const Video = () => (
<>
  <Hidden mdUp>
    <img src="./mobile.m4v" alt="Wilkommen Video" style={{ width: '100%' }} />
  </Hidden>
  <Hidden smDown>
    <div className="outer-container">
      <div className="inner-container">
        <div className="video-overlay">
          <div id="videoTextOverlay">
            <h1 id="videoHeading"><span style={{color: "#bebdbd"}}>Make it</span> <span style={{color: "#cc0300"}}>your</span><span style={{color: "#bebdbd"}}> own</span></h1>
            <p id="videoText">
              {'Ein Bild sagt mehr als 1000 Worte, wie viel sagt dann ein Video?'}
            </p>
            <div id="moreInfo">
              <ExpandMoreIcon style={{fontSize: "200px"}}/>
            </div>
          </div>
        </div>
        <video id="player" autoPlay muted loop src="video.mp4">
          {'Ihr Browser unterstützt leider kein Video Playback'}
        </video>
      </div>
    </div>
    <div id="siteStart" />
  </Hidden>
</>
);

export default Video;
