import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Video from '../components/video';
import SiteText from '../components/sitetext';
import '../components/css/index.css';

export default () => {
  const site = useStaticQuery(graphql`
    query indexDataQuery
    {
      wordpressPage(wordpress_id: { eq: 40 }) {
        title
        content
      }
    }
    `);
  return (
    <Layout title="Startseite">
      <div id="video">
        <Video />
      </div>
      <SiteText>
          <h1 className="siteText">{site.wordpressPage.title}</h1>
          <div id="mainText" dangerouslySetInnerHTML={{ __html: site.wordpressPage.content }} />
      </SiteText>
    </Layout>
  );
};
